<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="table-heading,card"
    :loading="activity.isFirstLoad"
  >
    <VRow>
      <SaveHeader
        :owner="this"
        :mode="mode"
      />
      <VCol style="flex: 1 1 auto">
        <VRow
          row
          wrap
        >
          <VCol
            md="6"
            cols="12"
          >
            <VCard>
              <VCardTitle>Details</VCardTitle>
              <VCardText>
                <h3 v-if="form.city">
                  Current City: {{ form.city.name }}
                </h3>
                <TextField
                  v-model="form.title"
                  label="Title"
                  placeholder="Enter title..."
                  :error="$v.form.title.$error"
                  @input="$v.form.title.$touch()"
                  @blur="$v.$touch()"
                />
                <Editor
                  v-model="form.content"
                  auto-grow
                  filled
                  label="Description"
                  placeholder="Type content here..."
                  outlined
                />
                <GoogleDriveUI
                  style="text-align: right"
                  :rounded="true"
                  :outlined="true"
                  :can-connect-on-no-token="true"
                  button-class="px-4 dashed text-right"
                  :files="form.files"
                  @addFile="onAddFile"
                  @removeFile="onRemoveFile"
                />
                <FileUpload
                  text="Attach Files"
                  style="text-align: right"
                  :as-button="true"
                  button-class="px-4 dashed text-right"
                  :outlined="true"
                  :rounded="true"
                  :multiple="true"
                  icon="cloud_upload"
                  :files="form.files"
                  @removeFile="onRemoveFile"
                  @formData="onAddFile"
                />
              </VCardText>
            </VCard>
            <DynamicInputs
              v-model="form.metadata"
              :card="false"
              :input-name="featureName('Metadata')"
              :enable-files="true"
              @new:input="onNewMetadata"
              @update:input="onUpdateMetadata"
              @update:inputs="onUpdateMultipleMetadata"
              @remove:input="onRemoveMetadata"
              @remove:inputs="onRemoveMultipleMetadata"
            />
          </VCol>
          <VCol
            md="6"
            cols="12"
          >
            <VCard>
              <VCardTitle>Associations</VCardTitle>
              <VCardText>
                <Autocomplete
                  v-model="form.programs"
                  multiple
                  :items="options.programs"
                  :placeholder="`Attach to ${featureName('Programs')}`"
                />
                <Autocomplete
                  v-model="form.primary_contents"
                  multiple
                  :items="options.primary_contents"
                  :placeholder="`Attach to ${featureName('Content')}`"
                />
                <Autocomplete
                  v-model="form.deliverables"
                  multiple
                  :items="options.deliverables"
                  :placeholder="`Attach to ${featureName('Deliverables')}`"
                />
                <Autocomplete
                  v-model="form.faqs"
                  multiple
                  :items="options.faqs"
                  :placeholder="`Attach to ${featureName('FAQs')}`"
                />
              </VCardText>
            </VCard>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>

<script>
import DynamicInputs from "@/components/Elements/Forms/DynamicInputs";
import FileUpload from "@/components/Elements/Forms/FileUpload";
import GoogleDriveUI from "@/components/Elements/Forms/GoogleDriveUI";
import SaveHeader from "@/components/Elements/Navigation/SaveHeader";
import FormMixin from "@/mixins/Form";
import MetadataMixin from "@/mixins/Metadata";
import { mapActions } from "vuex";
import { required, minLength, between } from "vuelidate/lib/validators";
export default {
  name: "SupplementSave",
  components: {
    DynamicInputs,
    FileUpload,
    GoogleDriveUI,
    SaveHeader,
  },
  mixins: [FormMixin, MetadataMixin],
  data() {
    return {
      modelType: "Supplement",
      hasFiles: true,
      options: {
        calendar: [],
        // reports: [],
        deliverables: [],
        programs: [],
        primary_contents: [],
        countries: [],
      },
      defaultForm: {
        content: "",
        metadata: [],
        deliverables: [],
        // reports: [],
        primary_contents: [],
        programs: [],
        files: [],
        visible_to: [],
      },
    };
  },
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(4),
      },
    },
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "supplement/getFormConfig",
      doSave: "supplement/save",
      doGet: "supplement/getOne",
    }),
    onGetFormConfig() {
      return this.doGetFormConfig();
    },
    onSave() {
      this.form.reports = this.prepareRelationIds(this.form.reports);
      this.form.primary_contents = this.prepareRelationIds(
        this.form.primary_contents
      );
      this.form.programs = this.prepareRelationIds(this.form.programs);
      this.form.deliverables = this.prepareRelationIds(this.form.deliverables);
      this.form.faqs = this.prepareRelationIds(this.form.faqs);
      return this.saveFormModel(this.doSave);
    },
    onGet(id) {
      return this.doGet(id);
    },
  },
};
</script>

