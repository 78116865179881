<template>
  <VRow column>
    <VCol style="flex: 0 0 64px">
      <h1>{{ featureName(modelTypeTitle) }}</h1>
    </VCol>
    <VCol
      mb-2
      shrink
      class="pa-5"
    >
      <Stats
        :stats="stats"
        :options="statOptions"
      >
        <template slot="append-actions">
          <VCol
            px-0
            class="mt-6 text-right"
          >
            <ExpandableButton
              icon=""
              button-class="mr-3 text-center"
              color="info"
              :text="featureName(modelTypeAltTitle)"
              :depressed="true"
              :large="true"
              :flex="true"
              :block="$vuetify.breakpoint.smAndDown"
              :to="{
                name: `${modelTypeAltRoute}.index`,
                params: {team: $team.slug}
              }"
            />
            <ExpandableButton
              v-if="$isUserMentor || $isUserAdmin"
              icon="add"
              button-class="wethrive px-5 d-grid"
              button-style="display: grid;"
              :large="true"
              :flex="true"
              :block="$vuetify.breakpoint.smAndDown"
              :to="{
                name: `${modelType}.create`,
                params: {team: $team.slug}
              }"
            />
          </VCol>
        </template>
      </Stats>
    </VCol>
    <VCol style="flex: 1 1 auto">
      <DataViewUI
        :data="data"
        :headers="headers"
        :is-loading="activity.isLoading"
        @paginate="onGetMore"
        @search="onSearch"
      >
        <template
          slot="items-cells"
          slot-scope="props"
        >
          <td>{{ props.item.title }}</td>
          <template v-if="$isUserAdmin">
            <td>
              <UserModal :user="props.item.user" />
            </td>
          </template>
          <td>{{ props.item.deliverables_count || 0 }}</td>
          <td class="actions">
            <Menu
              :disabled="getIsDisabled(props.item)"
              :loading="props.item.isLoading"
            >
              <VListItem
                v-if="$isUserAdmin || belongsToMe(props.item.user_id)"
                :href="getHrefForItem(`${modelTypeRoute}.update`, props.item.id)"
                @click.prevent="onSetFormDialogModel(props.item)"
              >
                <VListItemTitle>
                  <VIcon small>
                    edit
                  </VIcon>&nbsp;Edit
                </VListItemTitle>
              </VListItem>
              <VListItem
                :href="getHrefForItem(`${modelTypeRoute}.view`, props.item.id)"
                @click.prevent="onSetViewDialogModel(props.item)"
              >
                <VListItemTitle>
                  <VIcon small>
                    visibility
                  </VIcon>&nbsp;View
                </VListItemTitle>
              </VListItem>
              <VListItem
                v-if="props.item.userNeedsToSubmitReports"
                :href="getHrefForItem(`${modelTypeRoute}.submit`, props.item.id)"
                @click.prevent="setSubmittedDialogData(props.item, 'reports')"
              >
                <VListItemTitle>
                  <VIcon small>
                    save
                  </VIcon>
                  &nbsp;
                  Submit {{ featureName('Reports') }}
                </VListItemTitle>
              </VListItem>
              <VListItem
                v-if="props.item.userNeedsToSubmitDeliverables"
                :href="getHrefForItem(`${modelTypeRoute}.submit`, props.item.id)"
                @click.prevent="setSubmittedDialogData(props.item, 'deliverables')"
              >
                <VListItemTitle>
                  <VIcon small>
                    save
                  </VIcon>
                  &nbsp;
                  Submit {{ featureName('Deliverables') }}
                </VListItemTitle>
              </VListItem>
              <VListItem
                v-if="$isUserAdmin"
                @click.prevent="onDuplicateItem(props.item)"
              >
                <VListItemTitle>
                  <VIcon small>
                    content_copy
                  </VIcon>&nbsp;Duplicate
                </VListItemTitle>
              </VListItem>
              <VListItem
                v-if="$isUserAdmin || belongsToMe(props.item.user_id)"
                @click.prevent="onDeleteItem(props.item)"
              >
                <VListItemTitle color="error">
                  <VIcon
                    small
                    color="error"
                  >
                    delete
                  </VIcon>&nbsp;Delete
                </VListItemTitle>
              </VListItem>
            </Menu>
          </td>
        </template>
      </DataViewUI>
      <FormDialog
        :current-model="currentModel"
        :is-dialog-open="dialogs.form"
        :title="currentModel ? currentModel.title : ''"
        @toggle:form:dialog="onToggleFormDialog"
        @deleteItem="onDeleteItem"
        :model-type="modelType"
      >
        <Save
          ref="saveForm"
          mode="dialog"
          :id="currentModel ? currentModel.id : null"
        />
      </FormDialog>
      <Confirm ref="confirmDelete" />
      <Confirm
        ref="confirmDuplicate"
        confirm-color="info"
        cancel-color="disabled"
      />
      <Dialog
        max-width="640px"
        :is-dialog-open="dialogs.view"
        :title="`View ${featureName('Supplement')}`"
        @toggle:dialog="onToggleViewDialog"
      >
        <ViewComponent
          :id="currentModel ? currentModel.id : null"
          ref="viewItem"
          mode="dialog"
        />
      </Dialog>
      <Dialog
        max-width="640px"
        :is-dialog-open="submittedDialogs.reports"
        :title="`Submit ${featureName('Reports')}`"
        @toggle:dialog="onToggleSubmittedReportsDialog"
      >
        <SubmitReports
          mode="dialog"
          :items="submitted.data"
          :model-id="submittedModel.id"
          property="program_id"
        />
      </Dialog>
      <Dialog
        max-width="640px"
        :is-dialog-open="submittedDialogs.deliverables"
        :title="`Submit ${featureName('Deliverables')}`"
        @toggle:dialog="onToggleSubmittedDeliverablesDialog"
      >
        <SubmitDeliverables
          mode="dialog"
          :items="submitted.data"
          :model-id="submittedModel.id"
          property="supplement_id"
        />
      </Dialog>
    </VCol>
  </VRow>
</template>

<script>
import SearchMixin from "@/mixins/Search";
import HasStats from "@/mixins/HasStats";
import HasSubmittedData from "../../mixins/HasSubmittedData";
import Save from "./Save";
import ViewComponent from "./View";
import SubmitDeliverables from "@/components/Deliverables/Submit";
import { mapActions } from "vuex";

export default {
  name: "SupplementalIndex",
  components: {
    Save,
    ViewComponent,
    SubmitDeliverables,
  },
  mixins: [SearchMixin, HasStats, HasSubmittedData],
  data() {
    return {
      date: null,
      modelType: "supplement",
      modelTypeAlt: "content",
      config: {
        stats: {
          "-": "-",
        },
      },
    };
  },
  computed: {
    stats() {
      if (this.$isUserAdmin) {
        return this.config.stats;
      } else {
        return {
          supplements: this.config.stats.supplements,
        };
      }
    },
    headers() {
      let headers;
      if (this.$isUserAdmin) {
        headers = [
          {
            text: "Name",
            align: "left",
            sortable: true,
            value: "title",
          },
          {
            text: "Created By",
            align: "left",
            sortable: true,
            value: "user.name",
          },
          {
            text: this.featureName("Deliverables"),
            align: "left",
            sortable: true,
            value: "deliverables_count",
          },
        ];
      } else {
        headers = [
          {
            text: "Name",
            align: "left",
            sortable: true,
            value: "title",
          },
          {
            text: this.featureName("Deliverables"),
            align: "left",
            sortable: true,
            value: "deliverables_count",
          },
        ];
      }
      return headers;
    },
  },
  created() {
    this.doGetIndexConfig().then((result) => (this.config = result));
  },
  methods: {
    ...mapActions({
      doGetIndexConfig: "supplement/getIndexConfig",
      doGetSupplements: "supplement/getAll",
      doGetSupplement: "supplement/getOne",
      doDeleteSupplement: "supplement/delete",
      doDuplicateSupplement: "supplement/duplicate",
    }),
    onDuplicate(params) {
      return this.doDuplicateSupplement(params);
    },
    onDelete(supplement) {
      return this.doDeleteSupplement(supplement.id);
    },
    onGetAll(params) {
      return this.doGetSupplements(params);
    },
    onGet(id) {
      return this.doGetSupplement(id);
    },
  },
};
</script>

